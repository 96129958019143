window.app.service('PushNotificationServices', ['$location', '$rootScope', '$translate', 'API', 'ErrFactory', 'Notification', 'ReducedService',
                                       function( $location,   $rootScope,  $translate,    API ,  ErrFactory,   Notification,   ReducedService) {
  class ErrorPushNotificationSupport extends ErrFactory {
    constructor(){
      super('Push notifications aren\'t supported');
    }
  }

  async function CheckSupport () {
    if (!window.cordova || window.cordova.platformId === 'windows') throw new ErrorPushNotificationSupport;
    else if (ReducedService.push_notification) throw new ErrorPushNotificationSupport;
  }

  async function getFCMPlugin () {
    try {
      await Promise.resolve(app.AM.deviceready)
      await CheckSupport();

      if (window.FirebasePlugin) {
        let perm = await new Promise((res,rej)=> window.FirebasePlugin.hasPermission(res,rej)) || await new Promise((res,rej) => window.FirebasePlugin.grantPermission(res,rej));
        if (perm === false) throw new ErrorPushNotificationSupport;
        window.FirebasePlugin.onTokenRefresh(onTokenRefresh);
        window.FirebasePlugin.onMessageReceived(onNotification);
      } else throw new ErrorPushNotificationSupport;

    } catch (e) {
      throw new ErrorPushNotificationSupport;
    }

    return window.FirebasePlugin;
  }

  this.ErrorPushNotificationSupport = ErrorPushNotificationSupport;

  this.getToken = function () {
    return getFCMPlugin()
    .then((FCMPlugin) => {
      return new Promise((res,rej) => FCMPlugin.getToken(res,rej));
    });
  }

  this.subscribeToTopic = function (topic) {
    return getFCMPlugin()
    .then((FCMPlugin) => {
      return new Promise((res,rej) => {
        try {
          FCMPlugin.subscribe(topic)
          res();
        } catch (e) {
          rej(e);
        }
      });
    });
  };

  this.unsubscribeFromTopic = function (topic) {
    return getFCMPlugin()
    .then((FCMPlugin) => {
      return new Promise((res,rej) => {
        try {
          FCMPlugin.unsubscribe(topic);
          res()
        } catch (e) {
          rej(e)
        }
      });
    });
  }


  this.handleIntentCall = function(message) {
    onNotification(message);
  }

  function onTokenRefresh(token) {
    if ((!$rootScope.fcmToken || ($rootScope.fcmToken !== token && $rootScope.fcmToken !== 'pending')) && token) {
      $rootScope.fcmToken = 'pending';
      Promise.resolve(API.saveFcmToken(token))
      .then((res) => $rootScope.fcmToken = res)
      .catch(err => {
        if (err.notify) err.notify();
        else console.error(err);
      })
    }
  }

  function onNotification (message) {
    if (!message.title && message.aps && message.aps.alert && message.aps.alert.title) message.title = message.aps.alert.title;
    if (!message.body && message.aps && message.aps.alert && message.aps.alert.body) message.body = message.aps.alert.body;

    if (message.click_action) Action[message.click_action](message);
    else Action.show(message);
  }

  class Action {
    static async confirm_reading(message) {
      if (!message.tap) {
        await Notification.component({
          title: message.title,
          desc: message.body
        });
      }
      await Promise.resolve(API.PushConfirm.send({id:message.notification_id}).$promise);
    }

    static async open_link(message) {
      if (!message.tap) {
        await Notification.component({
          title: message.title,
          desc: message.body,
          buttons: [
            { label: 'cancel', type: 'cancel' },
            { label: 'open',   type: 'save'   }
          ]
        }).then((ans) => {
          if (ans === 2) this._open_link(message);
        })
      } else await this._open_link(message);
    }

    static async _open_link(message) {
      if (message.deep_link && message.deep_link.startsWith("#")) window.location.href = message.deep_link;
      $rootScope.$apply();
      window.navigator.splashscreen && window.navigator.splashscreen.hide();
      await Promise.resolve(API.PushConfirm.send({id:message.notification_id}).$promise);
    }

    static async open_project(message) {
      if (!message.tap) {
        if (!message.title) message.title = await $translate(['time-tracking.defaultEbsPushTitle']).then(t => t['time-tracking.defaultEbsPushTitle']);
        if (!message.body) message.body = await $translate(['time-tracking.defaultEbsPushBody']).then(t => t['time-tracking.defaultEbsPushBody']);

        await Notification.component({
          title: message.title,
          desc: message.body,
          buttons: [
            { label: 'cancel', type: 'cancel' },
            { label: 'open',   type: 'save'   }
          ]
        }).then((ans) => {
          if (ans === 2) this._open_project(message);
          else $rootScope.$broadcast('project.sync');
        })
      } else await this._open_project(message);
    }

    static async _open_project(message) {
      message.deep_link = `#/ebs/${message.assignment_id}`;
      await this._open_link(message);
    }

    static async show(message) {
      if (message.title && message.body) await Notification.component({
        title: message.title,
        desc: message.body
      });
    }
  }
}])
