/**
 * @ngdoc Services
 * @name Notification
 * @description Notification
 */
window.app.service('Notification', ['$translate', '$injector', '$timeout', '$rootScope', function ($translate, $injector, $timeout, $rootScope) {
  var self = this;

  var queue = Promise.resolve();

  this.component = async function ({title='', desc='', buttons=['ok'], htmlElement=null, scope={}}) {
    let $compile = $injector.get('$compile');
    return await new Promise((res) => {
      let angElem = angular.element('<notification title="title" desc="desc" buttons="buttons" on-hide="hide(ind)"></notification>');
      let $scope = $rootScope.$new(true);
      $scope.title = title;
      $scope.desc = desc;
      $scope.buttons = buttons;
      $scope.hide = (ind) => {
        res(ind);
      };

      if (htmlElement) {
        Object.assign($scope, scope);
        angElem.append(htmlElement)
      }

      angular.element(document).find('ng-view').append(
        $compile(angElem)($scope)
      );
    })

  }

  /**
   * alert
   * @description Show alert notification
   * @memberof Notification
   * @param {object} data title, desc, button
   * @param {function} calllback calllback
   */
  self.alert = function (data, callback = new Function) {
    return self.alert_p(data)
    .then((r) => {
      callback(r)
    });
  }

  self.alert_p = function (data, params = {}) {
    data.button = data.button || 'OK';

    return queue = queue
    .then(() => app.AM.sleep(200))
    .then(() => {
      return new Promise(function(res: Function,rej){
        $translate([data.title, data.desc, data.button], params)
          .then(function(transl){
            var title = (/(\?|!|:|\.)$/i.test( transl[data.title] )) ? transl[data.title] : transl[data.title] + ':';

            if (navigator.notification) {
              if (transl[data.desc].includes('https://customer.tempton.de/')) {
                let regex = /\<.*?\>/g;
                transl[data.desc] = transl[data.desc].replace(regex, '');
              }
              navigator.notification.alert(transl[data.desc], res, transl[data.title], transl[data.button]);

            // for browser customized modal
            } else if (!window.cordova) {
              var template = "<div class='custom-modal-content p20'>\
                <div>{{title}}</div>\
                <div>{{desc}}</div>\
                <div class='button-holder'>\
                <div class='ok-btn-holder'><button class='btn btn-blue-border' ng-click='close(true)'><span>{{ok}}</span></button></div></div>\
                </div>";

              var translations = {
                title: title,
                desc: transl[data.desc],
                okBtn: transl[data.button]
              };

              var callbackFunc = function(result) {
                var customModal = document.body.querySelector('#custom-modal');
                if (result) res(2);
                res(1);
                return $timeout(function () {
                  if (customModal) customModal.style.display = 'none';
                });
              };

              self.useModalService(template, translations, callbackFunc);

             } else {
              alert(title + '\n' + transl[data.desc]);
              res(2);
            }
          })
          .catch(function(err){
            rej(err);
          });
      });
    });
  };

  self.confirm_p = function (data, params = {}) {
    data.button = data.button || (data.buttons && data.buttons[1]) || 'OK';
    data.cancelButton = data.cancelButton || (data.buttons && data.buttons[0]) ||'Cancel';

    return Promise.resolve()
    .then(() => app.AM.sleep(200))
    .then(() => $translate([data.title, data.desc, data.button, data.cancelButton], params))
    .then((translations) => {
      let dataToPass = {
        title: translations[data.title],
        desc: translations[data.desc],
        buttons: [translations[data.cancelButton],translations[data.button]]
      };

      return new Promise((res) => this._confirm(dataToPass, (result) => res(result)));
    })
    .catch((err) => console.log(err));
  };

  /**
   * confirm
   * @description Show confirm notification
   * @memberof Notification
   * @param {object} data title, desc, buttons
   * @param {function} calllback calllback
   */
   self.confirm = function (data, calllback = new Function) {
     self.confirm_p(data)
     .then(calllback);
   }


  self._confirm = function (data, calllback) {
    if (navigator.notification || !window.cordova) {
      var template = "<div class='custom-modal-content p20'>\
        <div>{{title}}</div>\
        <div>{{desc}}</div>\
        <div class='button-holder'>\
        <div><button class='btn btn-cancel' ng-click='close(false)'><span>{{no}}</span></button></div>\
        <div><button class='btn btn-blue' ng-click='close(true)'><span>{{ok}}</span></button></div></div>\
        </div>";

      var translations = {
        title: data.title,
        desc: data.desc,
        okBtn: data.buttons[1],
        noBtn: data.buttons[0]
      };

      function callbackFunc(result) {
        var customModal = document.body.querySelector('#custom-modal');
        if (result) calllback(2);
        calllback(1);

        return $timeout(function() {
          if (customModal) customModal.style.display = 'none';
        });
      };

      return self.useModalService(template, translations, callbackFunc);

    } else {
      (confirm(data.title + '\n' + data.desc))? calllback(2) : calllback(1);
    }
  };

  /**
   * prompt
   * @description Show prompt notification
   * @memberof Notification
   * @param {object} data title, desc, buttons, defaultText
   * @param {function} calllback calllback
   */
  self.prompt = function (data, calllback) {

    if (navigator.notification) {
      navigator.notification.prompt(
        data.desc,
        calllback,
        data.title,
        data.buttons,
        data.defaultText || ''
      );
    } else {
      var res = prompt(data.title + ':\n' + data.desc);
      calllback({
        buttonIndex: (res)? 1:0,
        input1: res
      });
    }
  };

  /**
   * message
   * @description Show message notification
   * @memberof Notification
   * @param {string} message
   */
  self.message = function (message) {
    const $rootScope = angular.element(document.body).injector().get('$rootScope');
    $rootScope.$broadcast('shot_message', {message});  
  };

  self.error = function (message, calllback) {
    if (_.isArray(message)) {
      message = message.join('\n');
    }

    $translate('notice').then(function (translation) {
      self.alert({
        title: translation,
        desc: message
      }, calllback)
    });

  };

  self.useModalService = function useModalService (template, translations, callback) {
    return (new Promise(function(res, rej) {
      var ModalService = $injector.get('ModalService');
      return ModalService.showModal({
        template: template,
        controller: ['$scope', 'close',function($scope, close) {
          $scope.close = close;
          $scope.title = translations.title || '';
          $scope.desc = translations.desc || '';
          $scope.ok = translations.okBtn || '';
          $scope.no = translations.noBtn || '';
        }]
      }).then(function(modal) {
        var customModal = document.body.querySelector('#custom-modal');
        var popup = modal.element[0];

        if (modal.scope.desc.includes('</')) {
          popup.children[1].innerHTML = modal.scope.desc;
        }

        if (!customModal) {
          customModal = document.createElement('DIV');
          customModal.setAttribute('id', 'custom-modal');
          var overlay = document.createElement('DIV');
          overlay.classList.add('modal-overlay');
          customModal.appendChild(overlay);
          customModal.appendChild(popup);
          document.body.appendChild(customModal);
        }

        customModal.appendChild(popup);
        customModal.style.display = 'block';

        return modal.close
          .then(callback)
          .catch(function(err) {
            console.error(err);
          });
      });
    }));
  }

  return self;

}]);
