/**
 * @ngdoc Services
 * @name ModalWindowService
 * @description Methods for show or hide window service
 */
app.service('ModalWindowService', function (PopupRegisterService) {
  var self = this;
  var isActive = false;
  var list = [];

  var popapResterItem = new PopupRegisterService.PopupRegisterItem({
    close: function(){ self.hide() },
    skipDefaultAction: true
  });

  self.setList = function (items) {
    list = items || [];
  };

  self.getList = function () {
    return list;
  };

  self.isActive = function () {
    return isActive;
  };

  self.show = function (items) {
    self.setList(items);
    isActive = true;
    PopupRegisterService.attach(popapResterItem);
  };

  self.hide = function () {
    isActive = false;
    self.setList(null);
    PopupRegisterService.detach(popapResterItem);
  };

  self.getStyle = function () {
    return {
      left: '32px',
      right: '32px',
      top: (window.innerHeight - 244) / 2 + 'px',
    };
  };

  return self;
});
