window.app.service('HelpersService', function () {
  let self = this;

  self.debounce = function debounce(func, wait, immediate) {
    var timeout;

    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  self.normalizeName = function(name) {
    var normalizedName = name || '';

    normalizedName = normalizedName.trim();
    normalizedName = normalizedName.replace(/\u00dc/g, "Ue")
        .replace(/\u00d6/g, "Oe")
        .replace(/\u00c4/g, "Ae")
        .replace(/\u00fc/g, "ue")
        .replace(/\u00f6/g, "oe")
        .replace(/\u00e4/g, "ae")
        .replace(/\u00df/g, "ss")
        .replace(/\//g, "-")
        .replace(/\./g, "-")
        .replace(/-+/g, "-")
        .replace(/(?![A-Za-z0-9\-\.???????])./g, "-");

    return normalizedName;
  };

  return self;
});
