window.app.service('HolidaysService', ['$filter', 'API', 'DBFactory', 'ErrFactory', function ($filter, API, DBFactory, ErrFactory) {
  class Holidays {
    constructor() {}

    static loadHolidays(assignmentState, arStart, arEnd) {
      return Promise.resolve(this.getOwn(assignmentState))
      .then(data => {
        if (!!data && data.length) return data;
        else return this.requestHolidays();
      })
      .then(res => {
        let s = new Date(arStart);
        let e = new Date(arEnd);
        s.setHours(0,0,0,0);
        e.setHours(0,0,0,0);
        return res.filter(h => +h.date >= +s && +h.date <= +e);
      });
    }

    static requestHolidays() {
      let from = Weeks.getStartOfWeek(new Date(new Date().getTime() - 8*7*24*60*60*1000));
      let to   = Weeks.getEndOfWeek(  new Date(new Date().getTime() + 1*7*24*60*60*1000));

      from = $filter('date')(from, 'yyyy-MM-dd');
      to   = $filter('date')(to,   'yyyy-MM-dd');

      return Promise.resolve(API.getHolidays(from, to))
      .then(res => res.map(h => {
        let date = new Date(h.date);
        date.setHours(0,0,0,0);
        h.date = date;
        h.state_iso = h.state_iso || 'null';
        return h;
      }))
      .then(res => DBFactory.then(ds => ds.db)
        .then(db => db.clear(COLECTION_NAME).then(() => db.put(COLECTION_NAME, res)))
      )
      .catch(err => {
        if (err instanceof ErrFactory) err.notify();
        else console.error(err);
      });
    }

    static getOwn(zip) {
      let state = zip ? typeof zip === 'object' ? zip.state_iso : typeof zip === 'string' ? zip : 'null' : 'null';
      return Promise.resolve(DBFactory).then(ds => ds.db)	
      .then((db) => db.valuesByIndex(COLECTION_NAME, 'state_iso', ydn.db.KeyRange.only(state || 'null'), 2000))
      .catch(err => {
        if (err instanceof ErrFactory) err.notify();
        else console.error(err);
      });
    }

  }
  const COLECTION_NAME = 'holidays';
  return Holidays;
}]);

