window.app.service('PNListService', ['API', 'AuthService', 'DBFactory', 'ErrFactory', function (API, AuthService, DBFactory, ErrFactory) {
  class PushNotificationList {
    constructor() {}

    static getPN(page, column, dir) {
      return Promise.resolve(API.getPN(page, column, dir))
      .catch(err => {
        if (err instanceof ErrFactory) err.notify();
        else console.error(err);
      });
    }

    static markAsRead(id) {
      return Promise.resolve(API.PushConfirm.send({ id }).$promise)
      .then(() => DBFactory.then((ds) => ds.db))
      .then(db => db.remove('unread_notifications', id));
    }

    static getOwn(types: string[]) {
      let user_id = AuthService.userId;
      if (user_id) return DBFactory.then((ds) => ds.db)
      .then(db   => db.valuesByIndex('unread_notifications', 'user_id', ydn.db.KeyRange.only(user_id), 2000))
      .then(list => types ? list.filter(item => types.find(type => type === item.trigger.trigger_type)) : types );
      else return Promise.resolve([]);
    }

    static cleanDB() {
      return DBFactory.then((ds) => ds.db)
      .then((db) => db.clear('unread_notifications'));
    }

    static saveToDB(data) {
      return DBFactory.then((ds) => ds.db)
      .then((db) => db.put('unread_notifications', data));
    }

    static sync() {
      return Promise.resolve(API.getPushNotifications('sent', ['vacation_request_approved', 'vacation_request_rejected', 'salary_report']))
      .then((data) => this.cleanDB().then(() => this.saveToDB(data.push_notifications.map(pn => Object.assign(pn, { user_id: AuthService.userId })))))
      .catch((e) => {
        if (e instanceof ErrFactory) e.notify();
        else console.error(e);
        return [];
      });
    }

  }

  return PushNotificationList;
}]);
