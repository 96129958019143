app.service('PopupRegisterService', function () {

  class PopupRegisterItem {
    constructor(data){
      this.close = data.close;
      this.skipDefaultAction = data.skipDefaultAction || false;
    }
  }

  class PopupRegister {
    constructor(){
      this._register = [];
    }

    attach (item) {
      this._register.push(item);
    }

    detach (item) {
      let ind = this._register.indexOf(item);
      if (~ind) {
        this._register.splice(ind,1);
      }
    }

    closeOne(item) {
      item.close();
      this.detach(item);
      return item.skipDefaultAction || false;
    }

    closeAll () {
      let answer = false;
      Array.from(this._register).forEach((item) => {
        item.close();
        answer = answer || item.skipDefaultAction || false;
      });
      this._register = [];
      return answer;
    }

    get length () { return this._register.length }
    get PopupRegisterItem() { return PopupRegisterItem }
  }

  return new PopupRegister;

});
